<template>
<!--此部分根据设计自动生成，手动修改节点标记daltag="manual"属性，则该节点及子节点不会被自动生成代码覆盖，如发现其他需要调整框架情形请联系:alex.c@daline.com.cn-->
<div id="Newscenter" style="width:100vw">
  <h4 class="title">新闻中心</h4>

  <div id="a26b37c5">
    <div id="aa668d52">
      <img :src="card1.articleImg" id="abb9c507"/>
      <div id="a6d8735c">
        <div id="d6c9b86b">
          {{ card1.articleTitle | cardtitlefilter}}
        </div>
        <div id="ae3a1838">
          <div id="a4f842e9" v-html="contentFilter(card1.articleContent)">
          </div>
        </div>
        <div id="d8d7569d">
          <el-button id="a2947c7d" @click="clicka2947c7d(card1)">
            查看详情
          </el-button>
          <div id="f604c2aa">
            {{ card1.insertTime }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div id="a380c7b7">
    <div id="ab115b91">
      <div id="a0e6f093">
        <img :src="card2.articleImg" id="affcb37e"/>
        <div id="ace145cc">
          {{ card2.articleTitle | cardtitlefilter}}
        </div>
        <div id="a709f551">
          <div id="a5f11bb0" v-html="contentFilter(card2.articleContent)">
          </div>
        </div>
        <div id="a8d62985">
          <el-button id="a0ea056b" @click="clicka0ea056b(card2)">
            查看详情
          </el-button>
          <div id="f9c136e0">
            2020.12.21
          </div>
        </div>
      </div>
    </div>
    <div id="c59d84da">
      <div id="a343286a">
        <img :src="card3.articleImg" id="a1b76a3f"/>
        <div id="c69e3f75">
          {{ card3.articleTitle | cardtitlefilter}}
        </div>
        <div id="aa958461">
          <div id="a4e0a90c" v-html="contentFilter(card3.articleContent)">
          </div>
        </div>
        <div id="ed7de2d7">
          <el-button id="a4061810" @click="clicka4061810(card3)">
            查看详情
          </el-button>
          <div id="a43e2e43">
            2020.12.21
          </div>
        </div>
      </div>
    </div>
    <div id="e030b9a9">
      <div id="cc1bf591">
        <img :src="card4.articleImg" id="ddf1427e"/>
        <div id="b3f35342">
          {{ card4.articleTitle | cardtitlefilter}}
        </div>
        <div id="dca593f6">
          <div id="a37c58ae" v-html="contentFilter(card4.articleContent)">
          </div>
        </div>
        <div id="aac622c1">
          <el-button id="d7114488" @click="clickd7114488(card4)">
            查看详情
          </el-button>
          <div id="aa913374">
            2020.12.21
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="b7c27d84">
    <div id="e310cf4f" v-for="item of tbd_e310cf4f" v-bind:key="item.articleID">
      <img :src="item.articleImg" id="c8cd66b6"/>
      <div id="a1052604">
        <div id="a9f93626">
          {{ item.articleTitle }}
        </div>
        <div id="ababf36f">
          <div id="e4264fe0" v-html="ellipsis(item.content)">
<!--            <span>{{ item.content | ellipsis}}</span>-->
          </div>
        </div>
        <div id="a9533d28">
          <el-button id="ade0c73f" @click="clickade0c73f(item)">
            查看详情
          </el-button>
          <div id="ae1267dd">
            {{ item.insertTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <el-pagination
      id="a35943b0"
      :page-size="pageSize"
      layout="prev, pager, next, jumper"
      :total="pageTotalNum"
      @current-change="choosePage">
  </el-pagination>
  <div id="acbd73f8">
    <dal-compfloat id="cmpacbd73f8">
    </dal-compfloat>
  </div>
</div>
</template>
<script>
//此文件根据设计自动生成编写框架，请在指定位置编写逻辑代码，如发现框架需要调整的情形请联系:alex.c@daline.com.cn
//在此注释下方添加自定义import
import {
  DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList
} from '../api/dalapi.js'
//在此注释下方添加自定义函数
//此注释下方为自动引用部分，请不要修改
import dal_compfloat from '../components/dal-compfloat'
export default{
  filters: {//限制文本显示字数,超出部分用...代替
    cardtitlefilter (value) {
      if (!value) return ''
      // console.log('@@@@@@@@@@@@@', value)
      if (value.length > 10) {
        return value.slice(0,10) + '....'
      }
      return value
    },
  },
  components:{
    'dal-compfloat':dal_compfloat
  },
  data(){
    return{
      //在此注释下方添加自定义变量
      pageSize: 6,
      pageTotalNum: 0,
      tbd_e310cf4f: [],
      card1: "",
      card2: "",
      card3: "",
      card4: "",
    }
  },
  mounted(){
    this.refreshPage()
    this.getmostStarArticle()
  },
  computed:{
  },
  watch:{
  },
  methods:{
    choosePage (val) {
      this.refreshPage(val)
    },
    ellipsis (value) {
      if (!value) return ''
      // console.log('@@@@@@@@@@@@@', value)
      if (value.length > 35) {
        return value.slice(0,35) + "..."
      }
      return value
    },
    contentFilter (value) {
      if (!value) return ''
      if (value.length > 13) {
        return value.slice(0,13) + "..."
      }
      return value
    },
    async clicka2947c7d(item){
      this.$router.push({
        path: 'article',
        query: item,
      })
    },
    async clicka0ea056b(item){
      this.$router.push({
        path: 'article',
        query: item,
      })
    },
    async clicka4061810(item){
      this.$router.push({
        path: 'article',
        query: item,
      })
    },
    async clickd7114488(item){
      this.$router.push({
        path: 'article',
        query: item,
      })
    },
    async clickade0c73f(item){
      console.log('click_item', item)
      this.$router.push({
        path: 'article',
        query: item,
      })
    },
    async clickaa9244d4(){
      this.$router.push('article')
    },
    async clicka10a8ee9(){
      this.$router.push('article')
    },
    async clickac9f615b(){
      this.$router.push('article')
    },
    async clickcdd6688a(){
      this.$router.push('article')
    },
    async clicka1d14cce(){
      this.$router.push('article')
    },
    //在此注释下方添加自定义函数或事件
    async getmostStarArticle() {
      let dicInput = {
        ofield: "insertTime",
        sort: "",
        pnum: 1,
        records: 4,
        sort_rules:"preview",
        // ArticleType: 87,
      };
      dicInput.status = "0";
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList(
          dicInput
      );
      const data = JSON.parse(JSON.stringify(res.retContent))
      const stararticle = JSON.parse(data.retContent)
      console.log('22222', stararticle)
      const list = stararticle.articleList
      this.card1 = list[0]
      this.card2 = list[1]
      this.card3 = list[2]
      this.card4 = list[3]
    },
    async refreshPage(pageNumFresh = 1) {
      let dicInput = {
        pnum: pageNumFresh,
        records: this.pageSize,
        sort: "",
        ofield: "insertTime"
      };
      dicInput.status = "0";
      dicInput.top = "0"
      console.log("get input:", dicInput);
      const res = await DALINEWEB_ARTICLECENTER_V0X0X1_getArticleList(
          dicInput
      );
      this.freshData(res, (pageNumFresh - 1) * this.pageSize + 1);
    },
    async freshData(res, recordStart = 1) {
      console.log("articleListData", res);
      const articleListData = JSON.parse(JSON.stringify(res.retContent));
      const articleList = JSON.parse(articleListData.retContent)
      console.log('articleList', articleList)
      let num = recordStart;
      this.pageTotalNum = articleList.totalNum;
      this.tbd_e310cf4f = [];
      articleList.articleList.forEach((element) => {
        let tmp = element;
        tmp.num = num;
        tmp.e2009ea0 = num;
        num += 1;
        tmp.articleTitle = element.articleTitle;
        tmp.insertTime = element.insertTime;
        tmp.content = element.articleContent;
        tmp.articleImg = element.articleImg;
        this.tbd_e310cf4f.push(tmp);
      });
    },
  }
}
</script>
<style lang="scss" scoped>
#Newscenter{
  font-size: calc(0.5vw + 0.8em);
}
/*此部分根据设计自动生成，自定义样式追加到该部分结尾处*/
.title {
  padding-top: 100px;
  position: relative;
  padding-bottom: 0.5em;
  margin-bottom: 1em;
  text-align: center;
  font: 500 2em/1.5 "PingFang SC";
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 2em;
    height: 0.15em;
    background: #e74c3c;
  }
}
#a95ea5c0{
  width:1920px;
  height:640px;
  position:relative;
}
#a95ea5c0{
  background-image:url('../assets/img-e875e09d.png');
  background-size: cover;
}
#a454b2a2{
  margin-top:100px;
}
#a454b2a2{
  text-align:center;
  color:#1c1c1c;
  font-size:45px;
  font-family:PingFang SC;
}
#a26b37c5{
  margin: 2em 15vw;
  // width:1218px;
  position:relative;
  // margin-top:60px;
  // margin-left:351px;
}
#a26b37c5{
  background-color:rgba(255,255,255,1);
  border-radius: .5em;
  // border-radius:20px 20px 20px 20px;
  box-shadow:4px 6px 15px rgba(0,0,0,0.30);
}
#aa668d52{
  display: flex;
  position:relative;
}
#abb9c507{
  border-radius: .5em;
  width:65%;
  display:inline-block;
  vertical-align: middle;
}
#abb9c507{
  vertical-align:middle;
}
#a6d8735c{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  position:relative;
  margin-left:1em;
  margin-right: 1em;
  padding-top:30px;
  padding-bottom:25px;
  vertical-align: middle;
}
#d6c9b86b{
  /*width:350px;*/
  height:56px;
}
#d6c9b86b{
  text-align:left;
  color:#2b2b2b;
  font-size:2em;
  font-family:PingFang SC;
}
#ae3a1838{
  width:330px;
  position:relative;
  margin-top:61px;
  padding-left:24px;
}
#a4f842e9{
}
#a4f842e9{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#d8d7569d{
  display: flex;
  align-items: center;
}
#a2947c7d{
  // width:170px;
  padding: .5em 1.5em;
  display:inline-block;
  vertical-align: middle;
}
#a2947c7d{
  background-color:rgba(20,22,32,1);
  // border-radius:10px 10px 10px 10px;
  border-radius: .2em;
}
#a2947c7d{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#f604c2aa{
  margin-left: auto;
  display:inline-block;
  vertical-align: middle;
}
#f604c2aa{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#a380c7b7{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 2em 15vw;
  // width:1218px;
  position:relative;
  // margin-top:60px;
  // padding-left:351px;
}
#ab115b91{
  width:100%;
  position:relative;
  display:inline-block;
  vertical-align: middle;
}
#ab115b91{
  background-color:rgba(255,255,255,1);
  border-radius:20px 20px 20px 20px;
  box-shadow:4px 6px 15px rgba(0,0,0,0.16);
}
#a0e6f093{
  position:relative;
  padding-bottom:30px;
}
#affcb37e{
  width: 100%;
  border-radius: .5em;
  // width:385px;
  // height:242px;
}
#affcb37e{
  vertical-align:middle;
}
#ace145cc{
  /*width:350px;*/
  height:38px;
  margin-top:30px;
  padding-left:27px;
}
#ace145cc{
  text-align:left;
  color:#2b2b2b;
  font-size:30px;
  font-family:PingFang SC;
}
#a709f551{
  width:330px;
  position:relative;
  margin-top:16px;
  padding-left:28px;
}
#a5f11bb0{
}
#a5f11bb0{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#a8d62985{
  display: flex;
  margin-top:29px;
  padding-left:28px;
}
#a0ea056b{
  width:132px;
  display:inline-block;
  vertical-align: middle;
}
#a0ea056b{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#a0ea056b{
  color:#fff;
  font-size:20px;
  font-family:PingFang SC;
  border:transparent;
}
#f9c136e0{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#f9c136e0{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#c59d84da{
  width:385px;
  position:relative;
  margin-left:32px;
  display:inline-block;
  vertical-align: middle;
}
#c59d84da{
  background-color:rgba(255,255,255,1);
  border-radius:20px 20px 20px 20px;
  box-shadow:4px 6px 15px rgba(0,0,0,0.16);
}
#a343286a{
  width:385px;
  position:relative;
  padding-bottom:30px;
}
#a1b76a3f{
  width:385px;
  height:242px;
}
#a1b76a3f{
  vertical-align:middle;
}
#c69e3f75{
  /*width:350px;*/
  height:38px;
  margin-top:30px;
  padding-left:27px;
}
#c69e3f75{
  text-align:left;
  color:#2b2b2b;
  font-size:30px;
  font-family:PingFang SC;
}
#aa958461{
  width:330px;
  position:relative;
  margin-top:16px;
  padding-left:27px;
}
#a4e0a90c{
}
#a4e0a90c{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#ed7de2d7{
  margin-top:29px;
  padding-left:27px;
}
#a4061810{
  width:132px;
  display:inline-block;
  vertical-align: middle;
}
#a4061810{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#a4061810{
  color:#fff;
  font-size:20px;
  font-family:PingFang SC;
  border:transparent;
}
#a43e2e43{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#a43e2e43{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#e030b9a9{
  width:385px;
  position:relative;
  margin-left:31px;
  display:inline-block;
  vertical-align: middle;
}
#e030b9a9{
  background-color:rgba(255,255,255,1);
  border-radius:20px 20px 20px 20px;
  box-shadow:4px 6px 15px rgba(0,0,0,0.16);
}
#cc1bf591{
  width:385px;
  position:relative;
  padding-bottom:30px;
}
#ddf1427e{
  width:385px;
  height:242px;
}
#ddf1427e{
  vertical-align:middle;
}
#b3f35342{
  /*width:300px;*/
  height:38px;
  margin-top:30px;
  padding-left:27px;
}
#b3f35342{
  text-align:left;
  color:#2b2b2b;
  font-size:30px;
  font-family:PingFang SC;
}
#dca593f6{
  width:330px;
  position:relative;
  margin-top:16px;
  padding-left:27px;
}
#a37c58ae{
}
#a37c58ae{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#aac622c1{
  margin-top:29px;
  padding-left:28px;
}
#d7114488{
  width:132px;
  display:inline-block;
  vertical-align: middle;
}
#d7114488{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#d7114488{
  color:#fff;
  font-size:20px;
  font-family:PingFang SC;
  border:transparent;
}
#aa913374{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#aa913374{
  text-align:left;
  color:#9f9f9f;
  font-size:22px;
  font-family:PingFang SC;
}
#b7c27d84{
  width:1218px;
  margin-top:60px;
  padding-left:351px;
}
#e310cf4f{
  width:1218px;
  position:relative;
}
#c8cd66b6{
  width:385px;
  height:242px;
  padding-top:5px;
  display:inline-block;
  vertical-align: middle;
}
#c8cd66b6{
  vertical-align:middle;
}
#a1052604{
  width:803px;
  position:relative;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#a9f93626{
  width:3000px;
  height:56px;
  padding-left:27px;
}
#a9f93626{
  text-align:left;
  color:#2b2b2b;
  font-size:45px;
  font-family:PingFang SC;
}
#ababf36f{
  width:801px;
  position:relative;
  margin-top:20px;
  padding-left:27px;
}
#e4264fe0{
  width:801px;
}
#e4264fe0{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a9533d28{
  width:803px;
  position:relative;
  margin-top:30px;
}
#ade0c73f{
  width:170px;
  display:inline-block;
  vertical-align: middle;
}
#ade0c73f{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#ade0c73f{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#ae1267dd{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#ae1267dd{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a6bf6174{
  width:1218px;
  position:relative;
  margin-top:30px;
}
#ace99f6b{
  width:385px;
  height:242px;
  padding-top:5px;
  display:inline-block;
  vertical-align: middle;
}
#ace99f6b{
  vertical-align:middle;
}
#abe49e39{
  width:803px;
  position:relative;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#bafb7cbc{
  width:90px;
  height:56px;
  padding-left:2px;
}
#bafb7cbc{
  text-align:left;
  color:#2b2b2b;
  font-size:45px;
  font-family:PingFang SC;
}
#a735b07d{
  width:801px;
  position:relative;
  margin-top:20px;
  padding-left:2px;
}
#a7e9aa01{
  width:801px;
}
#a7e9aa01{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a3f4e523{
  width:803px;
  position:relative;
  margin-top:30px;
}
#aa9244d4{
  width:170px;
  display:inline-block;
  vertical-align: middle;
}
#aa9244d4{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#aa9244d4{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#a6a062e9{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#a6a062e9{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#ae33d4f8{
  width:1218px;
  position:relative;
  margin-top:30px;
}
#da8a7583{
  width:385px;
  height:242px;
  padding-top:5px;
  display:inline-block;
  vertical-align: middle;
}
#da8a7583{
  vertical-align:middle;
}
#dd9d3db0{
  width:803px;
  position:relative;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#a36bf9a5{
  width:90px;
  height:56px;
  padding-left:2px;
}
#a36bf9a5{
  text-align:left;
  color:#2b2b2b;
  font-size:45px;
  font-family:PingFang SC;
}
#a7a4652a{
  width:801px;
  position:relative;
  margin-top:20px;
  padding-left:2px;
}
#c7177183{
  width:801px;
}
#c7177183{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a0ff9889{
  width:803px;
  position:relative;
  margin-top:30px;
}
#a10a8ee9{
  width:170px;
  display:inline-block;
  vertical-align: middle;
}
#a10a8ee9{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#a10a8ee9{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#dbe2712a{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#dbe2712a{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a84bc6ac{
  width:1218px;
  position:relative;
  margin-top:30px;
}
#be3a5646{
  width:385px;
  height:242px;
  padding-top:5px;
  display:inline-block;
  vertical-align: middle;
}
#be3a5646{
  vertical-align:middle;
}
#a0ad4a2e{
  width:803px;
  position:relative;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#d523cf65{
  width:90px;
  height:56px;
  padding-left:2px;
}
#d523cf65{
  text-align:left;
  color:#2b2b2b;
  font-size:45px;
  font-family:PingFang SC;
}
#a42ed1c5{
  width:801px;
  position:relative;
  margin-top:20px;
  padding-left:2px;
}
#a14f5560{
  width:801px;
}
#a14f5560{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a3a02e12{
  width:803px;
  position:relative;
  margin-top:30px;
}
#ac9f615b{
  width:170px;
  display:inline-block;
  vertical-align: middle;
}
#ac9f615b{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#ac9f615b{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#cb46076d{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#cb46076d{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#f851ce6f{
  width:1218px;
  position:relative;
  margin-top:30px;
}
#b2bec4ad{
  width:385px;
  height:242px;
  padding-top:5px;
  display:inline-block;
  vertical-align: middle;
}
#b2bec4ad{
  vertical-align:middle;
}
#a429f657{
  width:803px;
  position:relative;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#c43fac1e{
  width:90px;
  height:56px;
  padding-left:2px;
}
#c43fac1e{
  text-align:left;
  color:#2b2b2b;
  font-size:45px;
  font-family:PingFang SC;
}
#a0f800fb{
  width:801px;
  position:relative;
  margin-top:20px;
  padding-left:2px;
}
#af769945{
  width:801px;
}
#af769945{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#d7cb9c9f{
  width:803px;
  position:relative;
  margin-top:30px;
}
#cdd6688a{
  width:170px;
  display:inline-block;
  vertical-align: middle;
}
#cdd6688a{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#cdd6688a{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#a3160de9{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#a3160de9{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a19edbf9{
  width:1218px;
  position:relative;
  margin-top:30px;
}
#f5ab88f5{
  width:385px;
  height:242px;
  padding-top:5px;
  display:inline-block;
  vertical-align: middle;
}
#f5ab88f5{
  vertical-align:middle;
}
#e7f9d671{
  width:803px;
  position:relative;
  margin-left:30px;
  display:inline-block;
  vertical-align: middle;
}
#ea710cb8{
  width:90px;
  height:56px;
  padding-left:2px;
}
#ea710cb8{
  text-align:left;
  color:#2b2b2b;
  font-size:45px;
  font-family:PingFang SC;
}
#a24bce84{
  width:801px;
  position:relative;
  margin-top:20px;
  padding-left:2px;
}
#b1bcdc6c{
  width:801px;
}
#b1bcdc6c{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a12c1b81{
  width:803px;
  position:relative;
  margin-top:30px;
}
#a1d14cce{
  width:170px;
  display:inline-block;
  vertical-align: middle;
}
#a1d14cce{
  background-color:rgba(20,22,32,1);
  border-radius:10px 10px 10px 10px;
}
#a1d14cce{
  color:#fff;
  font-size:22px;
  font-family:PingFang SC;
  border:transparent;
}
#b182ac10{
  padding-top:12px;
  display:inline-block;
  vertical-align: middle;
}
#b182ac10{
  text-align:left;
  color:#9f9f9f;
  font-size:30px;
  font-family:PingFang SC;
}
#a35943b0{
  margin-top:60px;
  padding-bottom:369px;
}
#acbd73f8{
  width:150px;
  position:fixed;
  z-index: 50;
  top:1638px;
  left:1740px;
}
#acbd73f8{
  background-color:rgba(20,22,32,1);
  border-radius:5px 5px 5px 5px;
}
/*在此注释下方添加自定义样式*/
</style>
